<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form
      class="ant-advanced-search-form"
      ref="searchForm"
      :form="searchForm"
    >
    <a-alert v-if="isRouterSearch" message="来自其他页面查询，如需恢复默认，请先点击清除查询" show-icon type="warning" />
      <a-row :gutter="24">
        <a-col :span="6" style="display: block">
          <a-form-item label="保险公司名称">
            <a-input
              placeholder="保险公司名称"
              v-model="searchForm.baseInsurance"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="修理厂名称">
            <a-input placeholder="修理厂名称" v-model="searchForm.repairShop" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="状态">
            <a-select v-model="searchForm.status">
              <a-select-option v-for="it in statusArray" :key="it.value">{{it.title}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="车牌号">
            <a-input placeholder="车牌号" v-model="searchForm.plateNo" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="银行流水匹配状态">
            <a-select v-model="searchForm.matchBankFlow">
              <a-select-option v-for="it in [{value: 'yes', title: '已匹配'},{value: 'no', title: '未匹配'},]" :key="it.value">{{it.title}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="转款日期">
            <a-range-picker v-model="searchForm.transferDateTime" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="录入日期">
            <a-range-picker v-model="searchForm.createdAt" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="车主">
            <a-input placeholder="车主" v-model="searchForm.carOwner" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="录单员">
            <a-input placeholder="录单员" v-model="searchForm.createUserName" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="订单号">
            <a-input placeholder="订单号" v-model="searchForm.uuid" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="报案号">
            <a-input placeholder="报案号" v-model="searchForm.reportNo" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="是否抵扣挂帐">
            <a-select v-model="searchForm.isDeductionCharge">
              <a-select-option v-for="it in [{title: '是', value: 'yes'},{title: '否', value: 'no'}]" :key="it.value">{{it.title}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="18">
          <a-tag color="blue">共有: {{pagination.total}}条数据</a-tag>
          <a-tag color="purple" v-if="pagination.total > 10000">数据大于1万条，无法统计数据，请进行查询筛选</a-tag>
          <template v-else>
            <a-tag color="purple">到账金额合计: {{tableOtherData && tableOtherData.received && tableOtherData.received ? tableOtherData.received : '无'}}</a-tag>
            <a-tag color="purple">已付款金额合计: {{tableOtherData && tableOtherData.payment && tableOtherData.payment ? tableOtherData.payment : '无'}}</a-tag>
            <a-tag color="purple">未付款金额合计: {{tableOtherData && tableOtherData.unpaid && tableOtherData.unpaid ? tableOtherData.unpaid : '无'}}</a-tag>
            <a-tag color="cyan">转换配件额度: {{tableOtherData && tableOtherData.accessories && tableOtherData.accessories ? tableOtherData.accessories : '无'}}</a-tag>
            <a-tag color="cyan">转换配件额度对应到账金额: {{tableOtherData && tableOtherData.accessoriesByReceived && tableOtherData.accessoriesByReceived ? tableOtherData.accessoriesByReceived : '无'}}</a-tag>
          </template>
        </a-col>
        <a-col :span="6" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick"> 查询 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a
            :style="{ marginLeft: '8px', fontSize: '12px' }"
            @click="expand = !expand"
          >
            {{ expand ? "收起" : "展开" }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
      
    </a-form>

    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom: 16px" type="flex" justify="end">
        <a-col style="display: flex;">
          <a-button
            icon="plus"
            style="
              background-color: #87d068;
              color: #fff;
              border-color: #87d068;
            "
            @click="addFormShowHandle"
            v-if="
              $root.$data.privateState.action.includes(
                '/AssessDamageOrder/Create'
              ) ||
              ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)
            "
            >新建</a-button
          >
          <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/ExportExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <a-button icon="cloud-download" style="background-color: #13c2c2;color: #fff;border-color: #13c2c2;" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/ExportExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" @click="exportExcel">导出定损单</a-button>
          <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <a-popover v-model="exportTypeSelectShow" title="请选择导出类型"  trigger="click" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || $root.$data.privateState.action.includes('/AssessDamageOrder/ExportStatistical') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
            <a-row slot="content" :gutter="[8, 24]" justify="center">
              <a-col style="text-align: center;" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
                <a-tag color="orange" @click="exportSettlementExcelClick('await')">导出待结算列表</a-tag>
              </a-col>
              <a-col style="text-align: center;" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
                <a-tag color="green" @click="exportSettlementExcelClick('done')">导出已结算列表</a-tag>
              </a-col>
              <a-col style="text-align: center;" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/ExportStatistical') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
                <a-tag color="purple" @click="exportSettlementExcelClick('statistics')">导出统计表</a-tag>
              </a-col>
            </a-row>
            <a-button icon="cloud-download" style="background-color: #909399;color: #fff;border-color: #909399;" >导出结算表或统计表</a-button>
          </a-popover>
          <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackSettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <div v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackSettlementExcel') || $root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
              <a-upload
                name="file"
                :file-list="uploadSettlementExcel"
                :action="uploadSettlementUrl"
                :headers="uploadSettlementHeaders"
                :multiple="false"
                @change="handleChangeUploadSettlementExcel"
            >
              <a-button icon="cloud-upload" type="primary">回传结算表</a-button>
            </a-upload>
          </div>
          <!-- <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackSettlementExcel') || $root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <a-button
            icon="plus"
            style="
              background-color: #87d068;
              color: #fff;
              border-color: #87d068;
            "
            @click="exportSettlementExcelClick('statistics_ins')"
            v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackSettlementExcel') || $root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)"
            >理赔业务保司分类表</a-button
          >
          <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackSettlementExcel') || $root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <a-button
            icon="plus"
            style="
              background-color: #87d068;
              color: #fff;
              border-color: #87d068;
            "
            @click="exportSettlementExcelClick('statistics_settlement')"
            v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackSettlementExcel') || $root.$data.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)"
            >理赔业务核算表及分配表</a-button
          > -->
          
          <!-- <a-divider type="vertical" style="height:100%" v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackInsDiscountAmountExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)" />
          <div v-if="$root.$data.privateState.action.includes('/AssessDamageOrder/PostBackInsDiscountAmountExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
            <a-upload
              name="file"
              :file-list="uploadInsDiscountAmountExcel"
              :action="uploadInsDiscountAmountUrl"
              :headers="uploadInsDiscountAmountHeaders"
              :multiple="false"
              @change="handleChangeUploadInsDiscountAmountExcel"
            >
              <a-button icon="dollar" style="background-color: #722ed1;color: #fff;border-color: #722ed1;">回传保险贴费</a-button>
            </a-upload>
          </div> -->
          <a-divider type="vertical" style="height:100%" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="column-height"
                />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'default'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'default'"
                      >默认</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'middle'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'middle'"
                      >中等</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'small'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'small'"
                      >紧凑</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"
                @click="
                  () => {
                    screenfull.toggle();
                    isFullscreen = !isFullscreen;
                  }
                "
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                icon="reload"
                @click="searchClick"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group
                    :value="showColumns"
                    @change="changeColumns"
                  >
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{ it }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="setting"
                />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="(record) => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 3000, y: tableHeight }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #needInvoice="needInvoice">
          {{ needInvoice ? "是" : "否" }}
        </template>
        <template #createdAt="createdAt">
          {{
            createdAt
              ? moment(createdAt).format("YYYY-MM-DD")
              : ""
          }}
        </template>
        <template #indemnityDateTime="indemnityDateTime">
          {{
            indemnityDateTime
              ? moment(indemnityDateTime).format("YYYY-MM-DD")
              : ""
          }}
        </template>
        <template #transferDateTime="transferDateTime">
          {{
            transferDateTime
              ? moment(transferDateTime).format("YYYY-MM-DD")
              : ""
          }}
        </template>
        <template #cutPercent="cutPercent">
          {{ cutPercent ? `${cutPercent}%` : "未填" }}
        </template>
        <template #cutPercentByScore="cutPercentByScore">
          {{ cutPercentByScore ? `${cutPercentByScore}%` : "未填" }}
        </template>
        <template #status="status, data">
          <a-tag :color="statusDic[status].color">{{
            statusDic[status].text
          }}</a-tag>
          <a-tag
            style="borderstyle: dashed"
            color="purple"
            v-if="data.matchedBankFlow && data.status > 0 && data.status < 300"
            >已匹配到银行流水</a-tag
          >
          <a-tag
            style="borderstyle: dashed"
            color="red"
            v-if="data.isBack"
            @click="handleTagShowLogs(data)"
            >已退回</a-tag
          >
          <a-tag
            style="borderstyle: dashed"
            color="cyan"
            v-if="data.isToAccessoriesOrder"
            >转为配件额度</a-tag
          >
          <a-tag
            style="borderstyle: dashed"
            :color="data.isDeductionCharge ? 'pink' : 'red'"
            v-if="(data.chargeScore && data.chargeScore > 0) || (data.deductionCharge && data.deductionCharge > 0)"
            >{{data.isDeductionCharge ? `订单抵扣挂帐金额` : "修理厂存在挂帐" }}</a-tag
          >
        </template>
        <template #action="data">
          <div style="display: flex; flex-wrap: wrap">
            <div style="padding-bottom: 10px">
              <a-tag color="green" @click="handleView(data)">查看</a-tag>
            </div>
            <div style="padding-bottom: 10px" v-if="data.status < 600 && $root.$data.privateState.userInfo.uuid === data.createUserUuid"><a-tag color="blue" @click="handleEdit(data, 'EditByCreatedUser')">编辑</a-tag></div>
            <div
              color="purple"
              style="padding-bottom: 10px"
              v-else-if="
                ($root.$data.privateState.action.includes('/Orders/EditAll') ||
                  ['SuperAdmin'].includes(
                    $root.$data.privateState.role
                  )) &&
                data.status < 999
              "
            >
              <a-tag @click="handleEdit(data, 'EditAll')">编辑</a-tag>
            </div>
            <div style="padding-bottom: 10px" v-if="($root.$data.privateState.action.includes('/AssessDamageOrder/FinancialConfirm') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)) && data.status === 600">
              <a-tag @click="handleEdit(data, 'FinancialConfirm')">财务审核</a-tag>
            </div>
            <div style="padding-bottom:10px" v-if="($root.$data.privateState.action.includes('/AssessDamageOrder/UploadInvoice') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)) && data.needInvoice === true && data.status === 510">
              <a-tag @click="handleToUpload" color="purple">上传发票</a-tag>
            </div>
            <!-- <div style="padding-bottom:10px" v-if="($root.$data.privateState.action.includes('/AssessDamageOrder/ConfirmTransfer') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)) && data.status === 600">
              <a-tag @click="handleView(data, 'ConfirmTransfer')" color="purple">确认转款</a-tag>
            </div> -->
            <div style="padding-bottom:10px" v-if="$root.$data.privateState.userInfo.uuid === data.lockedByUser && data.locked">
              <a-tag @click="handleUnlockOrder(data)" color="orange">解除锁定</a-tag>
            </div>

            <div>
              <a-popconfirm
                title="是否确认退回给网点? 本操作不可逆！"
                ok-text="是"
                cancel-text="否"
                v-if="data.status > 300 && data.status < 999 && ($root.$data.privateState.action.includes('/AssessDamageOrder/Back') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))"
                @confirm="handleBack(data)"
              >
                <a-tag color="red">退回给网点</a-tag>
              </a-popconfirm>
            </div>

            <div>
              <a-popconfirm
                title="是否确认退回给网点? 本操作不可逆！"
                ok-text="是"
                cancel-text="否"
                v-if="data.status == 999 && data.isToAccessoriesOrder && ($root.$data.privateState.action.includes('/AssessDamageOrder/BackAccessories') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))"
                @confirm="handleBackAccessories(data)"
              >
                <a-tag color="red">退回配件额度转换</a-tag>
              </a-popconfirm>
            </div>
            
            <div>
              <a-popconfirm
                title="是否确认删除该项?"
                ok-text="是"
                cancel-text="否"
                v-if="data.status <= 600 && $root.$data.privateState.userInfo.uuid === data.createUserUuid"
                @confirm="deleteBySelf(data)"
              >
                <a-tag color="red">删除</a-tag>
              </a-popconfirm>
              <a-popconfirm
                title="是否确认删除该项?"
                ok-text="是"
                cancel-text="否"
                v-else-if="($root.$data.privateState.action.includes('/AssessDamageOrder/Delete') ||
                  ['SuperAdmin'].includes(
                    $root.$data.privateState.role
                  )) &&
                data.status <= 600"
                @confirm="deleteByRole(data)"
              >
                <a-tag color="red">删除</a-tag>
              </a-popconfirm>
            </div>
          </div>
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <!-- 表单 -->
    <a-modal
      width="90%"
      :maskClosable="false"
      :destroyOnClose="true"
      :visible="addFormModalShow"
      @cancel="addFormModalShow = false"
      :title="`订单内容——(提交网点：${orderDetail.createUserName})`"
    >
      <template slot="footer">
        <div v-if="['Create', 'EditAll', 'EditByCreatedUser'].includes(addFormModalMode)">
          <a-button key="back" type="danger" @click="addFormModalShow = false"> 取 消 </a-button>
          <a-button key="submit" type="primary" ghost  @click="handleAddOk">
            确 认
          </a-button>
        </div>
        <div v-else>
          <a-button type="danger" ghost @click="handleBackShow">退回给网点</a-button>
          <a-button key="back" type="danger" @click="addFormModalShow = false"> 取 消 </a-button>
          <a-button key="submit" type="primary" ghost  @click="handleAddOk">
            确 认
          </a-button>
        </div>
      </template>
      <a-form-model
        ref="formRef"
        :model="addForm"
        :rules="addFormRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row :gutter="6">
          <a-divider>定损信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="车牌号" prop="plateNo">
              <a-input v-model="addForm.plateNo" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="保险公司分类" prop="baseInsurance">
              <a-select
                :disabled="!['Create', 'EditAll', 'EditByCreatedUser', 'FinancialConfirm'].includes(addFormModalMode)"
                v-model="addForm.baseInsurance"
                @change="baseInsuranceChange"
              >
                <a-select-option
                  v-for="it in baseInsuranceNameList"
                  :key="it"
                  >{{ it }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="保险公司归属" prop="baseInsurance">
              <a-select
                notFoundContent="请先选择保险公司分类"
                :disabled="!['Create', 'EditAll', 'EditByCreatedUser', 'FinancialConfirm'].includes(addFormModalMode)"
                v-model="addForm.lossRateUuid"
                @change="changeLossRateUuid"
              >
                <a-select-option
                  v-for="it in lossRateList"
                  :key="it.uuid"
                  >{{ it.insuranceName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="报案号/案件号" prop="reportNo">
              <a-input v-model="addForm.reportNo" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="定损员" prop="assessor">
              <a-input v-model="addForm.assessor" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="定损金额" prop="damageAmount">
              <a-input-number style="width:100%" v-model="addForm.damageAmount" :min="0" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="修理厂" prop="repairShopId">
              <a-select
                show-search
                v-model="addForm.repairShopId"
                placeholder="Select a person"
                option-filter-prop="children"
                style="width: 100%"
                :filter-option="filterRepairShopOption"
                @change="handleChangeRepairShopSelect"
              >
                <a-select-option v-for="it in repairShopList" :key="it.uuid">{{
                  it.name
                }}</a-select-option>
              </a-select>
              <a-alert v-if="customerOfflineScore" :message="`修理厂配件购买${customerOfflineScore}元`" banner :show-icon="false"/>
            </a-form-model-item>
          </a-col>
          <div v-if="addForm.repairShopId">
            <a-col :span="8">
              <a-form-model-item label="修理厂联系电话" prop="repairShopPhone">
                <a-input v-model="addForm.repairShopPhone" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="addForm.staffName">
              <a-form-model-item label="业务员姓名" prop="staffName">
                <a-input :disabled="true" v-model="addForm.staffName" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="addForm.staffPhone">
              <a-form-model-item label="业务员联系电话" prop="staffPhone">
                <a-input :disabled="true" v-model="addForm.staffPhone" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-col :span="8">
            <a-form-model-item label="是否开具发票" prop="needInvoice">
              <a-select
                v-model="addForm.needInvoice"
                @change="handelChangeNeedInvoiceSelect"
              >
                <a-select-option
                  v-for="it in [
                    { title: '是', value: 'yes' },
                    { title: '否', value: 'no' },
                  ]"
                  :key="it.value"
                  >{{ it.title }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="预估金额" prop="estimatedAmount">
              <a-input-number style="width:100%" :disabled="!['Create', 'EditAll', 'EditByCreatedUser'].includes(addFormModalMode)" v-model="addForm.estimatedAmount" :min="0" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="服务费提点比例" prop="cutPercent">
              <a-input-number style="width:100%" v-model="addForm.cutPercent" :min="0" :max="100" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="积分提点比例" prop="cutPercentByScore">
              <a-input-number style="width:100%" v-model="addForm.cutPercentByScore" :min="0" :max="100" />
              <a-alert message="未填写则默认为服务费提点比例" banner :show-icon="false"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-divider orientation="left">订单备注</a-divider>
            <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model="addForm.createdRemarks" />
          </a-col>
        </a-row>
        <a-divider>收款账户信息——{{addForm.needInvoice === "yes" ? "公账" : "私账"}}</a-divider>
        <a-row :gutter="6">
          <a-col :span="8">
            <a-form-model-item label="开户行" prop="bankAccount">
              <a-input v-model="addForm.bankAccount" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="银行账户" prop="bankName">
              <a-input v-model="addForm.bankName" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="银行卡号" prop="bankCard">
              <a-input v-model="addForm.bankCard" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider>费用信息</a-divider>
        <a-row :gutter="6">
          <a-col :span="12">
            <a-form-model-item label="费用名称(1)">
              <a-input v-model="addForm.costInfos[0].name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用金额(1)">
              <a-input-number style="width:100%" v-model="addForm.costInfos[0].amount" :min="0" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用名称(2)">
              <a-input v-model="addForm.costInfos[1].name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用金额(2)">
              <a-input-number style="width:100%" v-model="addForm.costInfos[1].amount" :min="0" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用名称(3)">
              <a-input v-model="addForm.costInfos[2].name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="费用金额(3)">
              <a-input-number style="width:100%" v-model="addForm.costInfos[2].amount" :min="0" />
            </a-form-model-item>
          </a-col>
          <a-divider>费用收款信息</a-divider>
          <a-row :gutter="6">
            <a-col :span="8">
              <a-form-model-item label="银行账户">
                <a-input v-model="addForm.bankNameByCost" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户行">
                <a-input v-model="addForm.bankAccountByCost" />
              </a-form-model-item>
            </a-col>
            
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-input v-model="addForm.bankCardByCost" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-divider>施救费收款信息</a-divider>
          <a-row :gutter="6">
            <a-col :span="8">
              <a-form-model-item label="银行账户">
                <a-input v-model="addForm.bankNameByRescueFee" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户行">
                <a-input v-model="addForm.bankAccountByRescueFee" />
              </a-form-model-item>
            </a-col>
            
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-input v-model="addForm.bankCardByRescueFee" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="施救费">
                <a-input-number style="width:100%" v-model="addForm.rescueFee" :min="0" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="施救费归属">
                <a-select
                v-model="addForm.rescueFeeBelong"
                @change="changeRescueFeeBelong"
              >
                <a-select-option
                  v-for="it in [
                    { title: '修理厂自身', value: 'lossShop' },
                    { title: '其他', value: 'other' },
                  ]"
                  :key="it.value"
                  >{{ it.title }}</a-select-option
                >
              </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-row>
        <!-- 新建订单填写付款信息 -->
        <a-divider>付款信息</a-divider>
        <div v-if="['Create', 'EditByCreatedUser'].includes(addFormModalMode)">
          <a-col :span="24">
              <a-form-model-item label="赔款付款账号"  prop="paymentBankCard" :labelCol="{span: 2}">
                <a-select
                  notFoundContent="请先选择"
                  :allowClear="true"
                  v-model="addForm.paymentBankCard"
                  @change="changePaymentBank"
                >
                  <a-select-option
                    v-for="it in paymentBankList"
                    :key="it.bankCard"
                    >
                    <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                    <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                    {{ it.bankAccount }}({{it.bankCard}})
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="费用付款账户" prop="paymentBankCardByPrivate" :labelCol="{span: 2}">
                <a-select
                  notFoundContent="请先选择"
                  :allowClear="true"
                  v-model="addForm.paymentBankCardByPrivate"
                  @change="changePaymentBank"
                >
                  <a-select-option
                    v-for="it in paymentBankList"
                    :key="it.bankCard"
                    >
                    <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                    <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                    {{ it.bankAccount }}({{it.bankCard}})
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
        </div>
        <a-divider>银行回执单关联</a-divider>
        <a-col :span="8">
          <a-button styel type="primary" icon="search" @click="onSearchBankFlowShow">银行回执单号查询</a-button>
        </a-col>
        <el-table
          stripe
          :data="bankFlowTable"
          style="width: 100%">
          <el-table-column
            prop="flowNo"
            label="流水号">
          </el-table-column>
          <el-table-column
            prop="accountName"
            label="账号名称">
          </el-table-column>
          <el-table-column
            prop="account"
            label="账号">
          </el-table-column>
          <el-table-column
            prop="transactionTime"
            label="到账时间">
          </el-table-column>
          <el-table-column
            prop="credits"
            label="到账金额">
          </el-table-column>
          <el-table-column
            prop="claimIndemnity"
            label="订单占用金额">
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="备注">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120">
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="removeBankFlowTableData(scope.$index, bankFlowTable)"
                type="text"
                size="small">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div
            v-if="
              $root.$data.privateState.action.includes(
                '/AssessDamageOrder/FinancialConfirm'
              ) ||
              ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)
              ||
              addFormModalMode === 'EditAll'
            "
          >
        <a-divider>财务信息</a-divider>
        <a-collapse accordion v-if="(orderDetail.invoiceImages && orderDetail.invoiceImages.length > 0) || (orderDetail.qiNiuImageUrl && orderDetail.qiNiuImageUrl.length > 0)">
          <a-collapse-panel key="1" header="发票图片(点击查看)">
            <div v-viewer>
              <img v-for="img in (orderDetail.invoiceImages ? orderDetail.invoiceImages : [])" :key="img"
                  height="100px"
                  alt="图片"
                  :src="`${$appBaseUrl}${img}`"
              />
            </div>
            <a-list bordered :data-source="orderDetail.qiNiuImageUrl">
              <a-list-item slot="renderItem" slot-scope="item">
                <a-descriptions title="" bordered>
                  <a-descriptions-item label="关联车牌">
                    {{item.item4.toString()}}
                  </a-descriptions-item>
                  <a-descriptions-item label="发票金额">
                    {{item.item3}}
                  </a-descriptions-item>
                  <a-descriptions-item label="发票图片">
                    <a-button v-for="img in item.item2" :key="img[1]" type="link" @click="windowOpen(img[1])">{{img[0]}}</a-button>
                  </a-descriptions-item>
                </a-descriptions>
              </a-list-item>
            </a-list>
          </a-collapse-panel>
        </a-collapse>
        <a-alert v-if="orderDetail.chargeScore && orderDetail.chargeScore.score > 0" :message="`当前订单修理厂存在挂帐金额:${orderDetail.chargeScore.score}`" type="error" />
        <a-row :gutter="6">
            <a-col :span="8">
              <a-form-model-item label="赔款到账日期" prop="indemnityDateTime">
                <a-date-picker v-model="addForm.indemnityDateTime" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="转款日期" prop="transferDateTime">
                <a-date-picker v-model="addForm.transferDateTime" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="提点金额">
                <a-input-number style="width:100%" v-model="addForm.cutAmount" :min="0" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="修理厂转款金额" prop="transferAmount">
                <a-input-number style="width:100%" v-model="addForm.transferAmount" :min="0" />
                <a-alert v-if="orderDetail.isDeductionCharge" :message="`订单抵扣挂帐金额:${orderDetail.deductionCharge}`" banner :show-icon="false"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="合计应付" prop="payableAmount">
                <a-input-number style="width:100%" v-model="addForm.payableAmount" :min="0" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="贴点金额">
                <a-input-number style="width:100%" v-model="addForm.subsidyAmount" :min="0" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="税费">
                <a-input-number style="width:100%" v-model="addForm.taxation" :min="0" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="赔款付款账号" prop="paymentBankCard" :labelCol="{span: 2}">
                <a-select
                  notFoundContent="请先选择"
                  :allowClear="true"
                  v-model="addForm.paymentBankCard"
                  @change="changePaymentBank"
                >
                  <a-select-option
                    v-for="it in paymentBankList"
                    :key="it.bankCard"
                    >
                    <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                    <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                    {{ it.bankAccount }}({{it.bankCard}})
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="费用付款账户" prop="paymentBankCardByPrivate" :labelCol="{span: 2}">
                <a-select
                  notFoundContent="请先选择"
                  :allowClear="true"
                  v-model="addForm.paymentBankCardByPrivate"
                  @change="changePaymentBank"
                >
                  <a-select-option
                    v-for="it in paymentBankList"
                    :key="it.bankCard"
                    >
                    <a-tag v-if="it.type === 'company'" color="blue">公户</a-tag>
                    <a-tag v-else-if="it.type === 'private'" color="purple">私户</a-tag>
                    {{ it.bankAccount }}({{it.bankCard}})
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" v-if="nowEditData.transferMode">
              <a-form-model-item  :labelCol="{span: 2}" label="转款模式">
                <a-tag color="purple">{{nowEditData.transferMode}}：{{transferModeHint}}</a-tag>
                <a-tag color="cyan" v-if="nowEditData.isToAccessoriesOrder">配件额度转换</a-tag>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-divider orientation="left">财务备注</a-divider>
              <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model="addForm.financialRemarks" />
            </a-col>
        </a-row>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="storageSendShow"
      @ok="storageSendOk"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
    >
      <a-textarea
        v-model="storageSendRemarks"
        placeholder="填写暂存原因"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
    <!-- 数据处理遮罩 -->
    <a-modal
      :visible="sending"
      :footer="null"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
    >
      <div style="text-align: center">
        <div>{{ sendingHint }}</div>
      </div>
    </a-modal>
    <!-- 订单详情 -->
    <a-modal
      width="90%"
      :destroyOnClose="true"
      :visible="showOrderDetail"
      :title="`订单详情(ID:${orderDetail.uuid} 提交网点:${orderDetail.createUserName})`"
      @cancel="showOrderDetail = false"
    >
      <template slot="footer">
        <div v-if="viewMode === 'View'">
          <a-button @click="showLogsDrawer = true">日志</a-button>
          <a-button key="back" type="primary" @click="printDetail"> 打印 </a-button>
          <a-button key="submit" type="danger" @click="showOrderDetail = false">
            关闭
          </a-button>
        </div>
        <div v-else-if="viewMode === 'ConfirmTransfer'">
          <a-button @click="showLogsDrawer = true">日志</a-button>
          <a-button key="back" type="danger" @click="showOrderDetail = false"> 关闭 </a-button>
          <a-button key="submit" type="success"  @click="handleConfirmTransfer">
            确认已转款: {{orderDetail.actualTransferAmount}}元
          </a-button>
        </div>
      </template>
      <vue-easy-print tableShow ref="easyPrint">
        <a-descriptions
          size="small"
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          title="定损信息"
        >
          <a-descriptions-item label="车牌号">
            {{ orderDetail.plateNo }}
          </a-descriptions-item>
          <a-descriptions-item label="保险公司分类">
            {{ orderDetail.baseInsurance }}
          </a-descriptions-item>
          <a-descriptions-item label="报案号/案件号">
            {{ orderDetail.reportNo }}
          </a-descriptions-item>
          <a-descriptions-item label="定损员">
            {{ orderDetail.assessor }}
          </a-descriptions-item>
          <a-descriptions-item label="定损金额">
            {{ orderDetail.damageAmount }}
          </a-descriptions-item>
          <a-descriptions-item label="修理厂">
            {{ orderDetail.repairShop }}
          </a-descriptions-item>
          <a-descriptions-item label="修理厂联系电话">
            {{ orderDetail.repairShopPhone }}
          </a-descriptions-item>
          <a-descriptions-item label="业务员姓名">
            {{ orderDetail.staffName }}
          </a-descriptions-item>
          <a-descriptions-item label="业务员联系电话">
            {{ orderDetail.staffPhone }}
          </a-descriptions-item>
          <a-descriptions-item label="是否开具发票">
            {{ orderDetail.needInvoice ? "是" : "否" }}
          </a-descriptions-item>
          <a-descriptions-item label="预估金额">
            {{ orderDetail.estimatedAmount }}
          </a-descriptions-item>
          <a-descriptions-item label="订单备注">
            {{ orderDetail.createdRemarks }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          size="small"
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          :title="`收款账户信息——${addForm.needInvoice ? '公账' : '私账'}`"
        >
          <a-descriptions-item label="开户行">
            {{ orderDetail.bankAccount }}
          </a-descriptions-item>
          <a-descriptions-item label="银行账户">
            {{ orderDetail.bankName }}
          </a-descriptions-item>
          <a-descriptions-item label="银行卡号">
            {{ orderDetail.bankCard }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider>银行回执单关联</a-divider>
        <el-table
          stripe
          :data="bankFlowTable"
          style="width: 100%">
          <el-table-column
            prop="flowNo"
            label="流水号">
          </el-table-column>
          <el-table-column
            prop="accountName"
            label="账号名称">
          </el-table-column>
          <el-table-column
            prop="account"
            label="账号">
          </el-table-column>
          <el-table-column
            prop="transactionTime"
            label="到账时间">
          </el-table-column>
          <el-table-column
            prop="credits"
            label="到账金额">
          </el-table-column>
          <el-table-column
            prop="claimIndemnity"
            label="订单占用金额">
          </el-table-column>
          <el-table-column
            prop="remarks"
            label="备注">
          </el-table-column>
        </el-table>
        <a-divider>费用信息</a-divider>
        <a-table :pagination="false" :rowKey="(record) => record.name" :columns="[{title: '费用名称', dataIndex: 'name'}, {title: '费用金额', dataIndex: 'amount'}]" :data-source="orderDetail.costInfos">
        </a-table>
        <a-descriptions
          size="small"
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          title="费用收款信息"
        >
          <a-descriptions-item label="开户行">
            {{ orderDetail.bankAccountByCost }}
          </a-descriptions-item>
          <a-descriptions-item label="银行账户">
            {{ orderDetail.bankNameByCost }}
          </a-descriptions-item>
          <a-descriptions-item label="银行卡号">
            {{ orderDetail.bankCardByCost }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          size="small"
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          title="其他费用信息"
        >
          <a-descriptions-item label="施救费">
            {{ orderDetail.rescueFee }}
          </a-descriptions-item>
          <a-descriptions-item label="施救费归属">
            {{ orderDetail.rescueFeeBelong === 'lossShop' ? '修理厂自身' : '其他' }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          size="small"
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          title="施救费收款信息"
        >
          <a-descriptions-item label="开户行">
            {{ orderDetail.bankAccountByRescueFee }}
          </a-descriptions-item>
          <a-descriptions-item label="银行账户">
            {{ orderDetail.bankNameByRescueFee }}
          </a-descriptions-item>
          <a-descriptions-item label="银行卡号">
            {{ orderDetail.bankCardByRescueFee }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions
          size="small"
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
          title="财务信息"
        >
          <div v-if="orderDetail.isToAccessoriesOrder">
            转换为配件额度
          </div>
          <div v-if="orderDetail.isDeductionCharge">
            订单抵扣挂帐金额：{{orderDetail.deductionCharge}}
          </div>
          <template>
            <a-descriptions-item label="赔款到账日期">
              {{
                orderDetail.indemnityDateTime
                  ? moment(orderDetail.indemnityDateTime).format("YYYY-MM-DD")
                  : ""
              }}
            </a-descriptions-item>
            <a-descriptions-item label="转款日期">
              {{
                orderDetail.transferDateTime
                  ? moment(orderDetail.transferDateTime).format("YYYY-MM-DD")
                  : ""
              }}
            </a-descriptions-item>
            <a-descriptions-item label="修理厂转款金额">
              {{ orderDetail.transferAmount }}
            </a-descriptions-item>
            <a-descriptions-item label="合计应付">
              {{ orderDetail.payableAmount ? orderDetail.payableAmount : GetPayableAmount(orderDetail) }}
            </a-descriptions-item>
            <a-descriptions-item label="服务费提点比例">
              {{ orderDetail.cutPercent }}%
            </a-descriptions-item>
            <a-descriptions-item label="积分提点比例">
              {{ orderDetail.cutPercentByScore }}%
            </a-descriptions-item>
            <a-descriptions-item label="贴点金额">
              {{ orderDetail.subsidyAmount }}
            </a-descriptions-item>
            <a-descriptions-item label="税费">
              {{ orderDetail.taxation }}
            </a-descriptions-item>
            <a-descriptions-item label="付款账户">
              {{ orderDetail.paymentBankName }}
            </a-descriptions-item>
            <a-descriptions-item label="付款开户行" span="2">
              {{ orderDetail.paymentBankAccount }}
            </a-descriptions-item>
            <a-descriptions-item label="赔款付款账户">
              {{ orderDetail.paymentBankAccount }}
            </a-descriptions-item>
            <a-descriptions-item label="赔款付款银行">
              {{ orderDetail.paymentBankName }}
            </a-descriptions-item>
            <a-descriptions-item label="赔款付款卡号">
              {{ orderDetail.paymentBankCard }}
            </a-descriptions-item>
            <a-descriptions-item label="费用付款账户">
              {{ orderDetail.paymentBankAccountByPrivate }}
            </a-descriptions-item>
            <a-descriptions-item label="费用付款银行">
              {{ orderDetail.paymentBankNameByPrivate }}
            </a-descriptions-item>
            <a-descriptions-item label="费用付款卡号">
              {{ orderDetail.paymentBankCardByPrivate }}
            </a-descriptions-item>
            <a-descriptions-item label="财务备注" >
              {{ orderDetail.financialRemarks }}
            </a-descriptions-item>
            <a-descriptions-item label="转款模式" v-if="orderDetail.transferMode">
              <a-tag color="green" v-if="orderDetail.transferMode === 'standard' || orderDetail.transferMode === '扣积分'">扣除积分</a-tag>
              <a-tag color="purple" v-else-if="orderDetail.transferMode === 'serviceCharge' || orderDetail.transferMode === '扣服务费'">扣除服务费</a-tag>
              <a-tag color="purple" v-else-if="orderDetail.transferMode === '组合'">组合</a-tag>
              <a-tag color="red" v-else>异常</a-tag>
            </a-descriptions-item>
            <a-descriptions-item label="转款计算" v-if="orderDetail.transferMode">
              <a-tag color="purple">{{orderDetail.transferMode}}：{{transferModeHint}}</a-tag>
            </a-descriptions-item>
          </template>
        </a-descriptions>
        <a-collapse accordion v-if="(orderDetail.invoiceImages && orderDetail.invoiceImages.length > 0) || (orderDetail.qiNiuImageUrl && orderDetail.qiNiuImageUrl.length > 0)">
          <a-collapse-panel key="1" header="发票图片(点击查看)">
            <div v-viewer>
              <img v-for="img in (orderDetail.invoiceImages ? orderDetail.invoiceImages : [])" :key="img"
                  height="100px"
                  alt="图片"
                  :src="`${$appBaseUrl}${img}`"
              />
            </div>
            <a-list bordered :data-source="orderDetail.qiNiuImageUrl">
              <a-list-item slot="renderItem" slot-scope="item">
                <a-descriptions title="" bordered>
                  <a-descriptions-item label="关联车牌">
                    {{item.item4.toString()}}
                  </a-descriptions-item>
                  <a-descriptions-item label="发票金额">
                    {{item.item3}}
                  </a-descriptions-item>
                  <a-descriptions-item label="发票图片">
                    <a-button v-for="img in item.item2" :key="img[1]" type="link" @click="windowOpen(img[1])">{{img[0]}}</a-button>
                  </a-descriptions-item>
                </a-descriptions>
              </a-list-item>
            </a-list>
          </a-collapse-panel>
        </a-collapse>
      </vue-easy-print>
    </a-modal>
    <!-- 回执单查询列表 -->
    <a-modal width="90%" :visible="bankFlowTableVisible"  :closable="false">
      <a-row style="padding:10px 0;" type="flex" justify="space-between" :gutter="16">
        <a-col :flex="2">
          <div style="line-height: 2.3;">
            <a-checkbox-group
              :options="bankFlowSearchFlags"
              v-model="bankFlowSearchFlagsSelected"
            >
              <span slot="label" slot-scope="{ value }" style="color: red">{{ value }}</span>
            </a-checkbox-group>
          </div>
        </a-col>
        <a-col :flex="3">
          <a-input addon-before="指定回执单流水号" v-model="appointBankFlow"/>
        </a-col>
        <a-col :flex="3" style="text-align: end;">
          <a-button styel type="primary" icon="search" @click="onSearchBankFlow">查询</a-button>
        </a-col>
      </a-row>
      <a-table
          :columns="bankFlowColumns"
          :rowKey="record => record.flowNo"
          :dataSource="bankFlowTableData"
          :scroll="{ x: 750 }"
          bordered
      >
        <template #transactionTime="transactionTime">
          {{moment(transactionTime).format('YYYY-MM-DD')}}
        </template>
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #status="status">
          <a-tag :color="bankFlowStatus[status].color">{{bankFlowStatus[status].text}}</a-tag>
        </template>
        <template #action="data">
          <a-button type="link" @click="selectBankFlowHandle(data)">选择</a-button>
        </template>
      </a-table>
      <template slot="footer">
        <a-button key="back" @click="handleSelectBankFlowCancel">
          关闭
        </a-button>
      </template>
    </a-modal>
    <!-- 回执单金额认领确认 -->
    <a-modal 
      width="80%"
      @ok="handleCheckBankFlowAmount"
      @cancel="bankFlowCheckAmountShow = false"
      :visible="bankFlowCheckAmountShow"
      :closable="false"
    >
      <a-descriptions v-if="awaitPushBankFlowData" title="回执单信息" layout="vertical" bordered>
        <a-descriptions-item label="流水号">{{awaitPushBankFlowData.flowNo}}</a-descriptions-item>
        <a-descriptions-item label="账号名称">{{awaitPushBankFlowData.accountName}}</a-descriptions-item>
        <a-descriptions-item label="账号">{{awaitPushBankFlowData.account}}</a-descriptions-item>
        <a-descriptions-item label="到账时间">{{awaitPushBankFlowData.transactionTime ? moment(awaitPushBankFlowData.transactionTime).format("YYYY-MM-DD") : ''}}</a-descriptions-item>
        <a-descriptions-item label="到账金额">{{awaitPushBankFlowData.credits}}</a-descriptions-item>
      </a-descriptions>
      请输入订单占用金额：<a-input-number id="inputNumber" v-model="bankFlowCheckAmount" :min="0.01" :max="awaitPushBankFlowData ? awaitPushBankFlowData.credits : 0.01" />
    </a-modal>
    <!-- 上传发票 -->
    <a-modal
      v-model="showUploadImageModal"
      :title="`上传发票 ${imageFilesOrderInfoString} `"
      width="90%"
      @ok="handleSetImageOk"
      @cancel="showUploadImageModal = false"
    >
      <a-row v-if="beforeImageFiles.length > 0">
        <a-divider>已上传图片</a-divider>
        <a-upload
          list-type="picture"
          :remove="handleRemoveImage"
          :before-upload="() => false"
          :fileList="beforeImageFiles"
          class="upload-list-inline"
        >
        </a-upload>
      </a-row>
      <a-row>
        <a-divider></a-divider>
        <a-upload-dragger
          name="imageFile"
          :multiple="true"
          :fileList="imageFiles"
          :before-upload="beforeUploadImage"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            发票上传
          </p>
          <p class="ant-upload-hint">
            仅支持上传JPG或PNG图片
          </p>
        </a-upload-dragger>
      </a-row>
    </a-modal>
    <!-- 退回 -->
    <a-modal
      v-model="backModalShow"
      :title="`退回订单——${this.nowEditUuid}`"
      width="90%"
      @ok="handleBackOk"
      @cancel="backModalShow = false"

    >
      <a-row>
        <a-textarea
          v-model="backMessage"
          placeholder="请填写退回说明"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-row>
    </a-modal>
    <!-- 结算表 -->
    <a-modal
      v-model="showExportSettlementExcelDateSelect"
      title="导出结算或统计表表(时间范围为转款时间)"
      @ok="exportSettlementExcel"
      @cancel="showExportSettlementExcelDateSelect = false"

    >
      <a-row>
        <a-range-picker style="width:80%" :disabled-date="c => c > moment().endOf('day')" v-model="exportSettlementExcelDate" />
      </a-row>
    </a-modal>
    <!-- 结算表2 -->
    <a-modal
      v-model="showExportStatisticalDateSelect"
      title="请选择导出时间"
      @ok="exportStatisticalExcel"
      @cancel="showExportStatisticalDateSelect = false"

    >
      <a-row>
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="exportStatisticalExcelTransferDateTime" />
      </a-row>
      <a-row>
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="exportStatisticalExcelInsOrderTime" />
      </a-row>
    </a-modal>
    <!-- 日志 -->
    <a-drawer
      title="订单日志"
      placement="right"
      width="320"
      :closable="false"
      :visible="showLogsDrawer"
      @close="showLogsDrawer = false"
    >
      <div v-if="!orderDetail.logs || orderDetail.logs.length < 1">
        <a-empty />
      </div>
      <div v-else>
        <a-timeline>
          <a-timeline-item v-for="(item, index) of orderDetail.logs" :key="index" :color="statusDic[item.StatusChange[0]].color">
            <a-card style="width: 100%" :title="item.Time" size="small">
              <p>
                <a-tag :color="statusDic[item.StatusChange[0]].color">{{statusDic[item.StatusChange[0]].text}}</a-tag><span v-if="item.StatusChange.length > 1">-></span> <a-tag v-if="item.StatusChange.length > 1" :color="statusDic[item.StatusChange[1]].color">{{statusDic[item.StatusChange[1]].text}}</a-tag>
              </p>
              <p>({{item.UserType}})-{{item.UserName}}</p>
              <p>{{item.Message}}</p>
              <template slot="actions" v-if="item.BeforeOrderJson && item.NowOrderJson">
                <a-button size="small" type="primary" icon="block" @click="showComparisonClick(index)">
                  查看数据变更
                </a-button>
              </template>
            </a-card>
          </a-timeline-item>
        </a-timeline>
      </div>
    </a-drawer>
    <a-drawer
      title="数据变更对比"
      placement="left"
      width="90%"
      :visible="showComparisonDrawer"
      @close="showComparisonDrawer = false"
    >
      <a-row v-if="comparisonLogs">
        <a-col :span="12">
          <a-card style="width: 100%" title="之前数据" size="small">
            <a-descriptions
              size="small"
              :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
              title="定损信息"
            >
              <a-descriptions-item label="车牌号">
                {{ comparisonLogs.BeforeOrderJson.PlateNo }}
              </a-descriptions-item>
              <a-descriptions-item label="保险公司分类">
                {{ comparisonLogs.BeforeOrderJson.BaseInsurance }}
              </a-descriptions-item>
              <a-descriptions-item label="报案号/案件号">
                {{ comparisonLogs.BeforeOrderJson.ReportNo }}
              </a-descriptions-item>
              <a-descriptions-item label="定损员">
                {{ comparisonLogs.BeforeOrderJson.Assessor }}
              </a-descriptions-item>
              <a-descriptions-item label="定损金额">
                {{ comparisonLogs.BeforeOrderJson.DamageAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="修理厂">
                {{ comparisonLogs.BeforeOrderJson.RepairShop }}
              </a-descriptions-item>
              <a-descriptions-item label="修理厂联系电话">
                {{ comparisonLogs.BeforeOrderJson.RepairShopPhone }}
              </a-descriptions-item>
              <a-descriptions-item label="业务员姓名">
                {{ comparisonLogs.BeforeOrderJson.StaffName }}
              </a-descriptions-item>
              <a-descriptions-item label="业务员联系电话">
                {{ comparisonLogs.BeforeOrderJson.StaffPhone }}
              </a-descriptions-item>
              <a-descriptions-item label="是否开具发票">
                {{ comparisonLogs.BeforeOrderJson.NeedInvoice ? "是" : "否" }}
              </a-descriptions-item>
              <a-descriptions-item label="预估金额">
                {{ comparisonLogs.BeforeOrderJson.EstimatedAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="订单备注">
                {{ comparisonLogs.BeforeOrderJson.CreatedRemarks }}
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions
              size="small"
              :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
              :title="`收款账户信息——${addForm.NeedInvoice ? '公账' : '私账'}`"
            >
              <a-descriptions-item label="银行账户">
                {{ comparisonLogs.BeforeOrderJson.BankName }}
              </a-descriptions-item>
              <a-descriptions-item label="开户行">
                {{ comparisonLogs.BeforeOrderJson.BankAccount }}
              </a-descriptions-item>
              <a-descriptions-item label="银行卡号">
                {{ comparisonLogs.BeforeOrderJson.BankCard }}
              </a-descriptions-item>
            </a-descriptions>
            <a-divider>银行回执单关联</a-divider>
            <el-table
              stripe
              :data="bankFlowTable"
              style="width: 100%">
              <el-table-column
                prop="flowNo"
                label="流水号">
              </el-table-column>
              <el-table-column
                prop="accountName"
                label="账号名称">
              </el-table-column>
              <el-table-column
                prop="account"
                label="账号">
              </el-table-column>
              <el-table-column
                prop="transactionTime"
                label="到账时间">
              </el-table-column>
              <el-table-column
                prop="credits"
                label="到账金额">
              </el-table-column>
              <el-table-column
                prop="claimIndemnity"
                label="订单占用金额">
              </el-table-column>
              <el-table-column
                prop="remarks"
                label="备注">
              </el-table-column>
            </el-table>
            <a-divider>费用信息</a-divider>
            <a-table :pagination="false" :rowKey="(record) => record.name" :columns="[{title: '费用名称', dataIndex: 'name'}, {title: '费用金额', dataIndex: 'amount'}]" :data-source="comparisonLogs.BeforeOrderJson.CostInfos">
            </a-table>
            <a-descriptions
              size="small"
              :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
              title="财务信息"
            >
              <div v-if="comparisonLogs.BeforeOrderJson.IsToAccessoriesOrder">
                转换为配件额度
              </div>
              <div v-else>
                <a-descriptions-item label="赔款到账日期">
                  {{
                    comparisonLogs.BeforeOrderJson.IndemnityDateTime
                      ? moment(comparisonLogs.BeforeOrderJson.IndemnityDateTime).format("YYYY-MM-DD")
                      : ""
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="转款日期">
                  {{
                    comparisonLogs.BeforeOrderJson.TransferDateTime
                      ? moment(comparisonLogs.BeforeOrderJson.TransferDateTime).format("YYYY-MM-DD")
                      : ""
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="修理厂转款金额">
                  {{ comparisonLogs.BeforeOrderJson.TransferAmount }}
                </a-descriptions-item>
                <a-descriptions-item label="合计应付">
                  {{ comparisonLogs.BeforeOrderJson.PayableAmount }}
                </a-descriptions-item>
                <a-descriptions-item label="服务费提点比例">
                  {{ comparisonLogs.BeforeOrderJson.CutPercent }}%
                </a-descriptions-item>
                <a-descriptions-item label="积分提点比例">
                  {{ comparisonLogs.BeforeOrderJson.CutPercentByScore }}%
                </a-descriptions-item>
                <a-descriptions-item label="贴点金额">
                  {{ comparisonLogs.BeforeOrderJson.SubsidyAmount }}
                </a-descriptions-item>
                <a-descriptions-item label="税费">
                  {{ comparisonLogs.BeforeOrderJson.Taxation }}
                </a-descriptions-item>
                <a-descriptions-item label="财务备注">
                  {{ comparisonLogs.BeforeOrderJson.FinancialRemarks }}
                </a-descriptions-item>
                <a-descriptions-item label="转款模式" v-if="comparisonLogs.BeforeOrderJson.TransferMode">
                  <a-tag color="green" v-if="comparisonLogs.BeforeOrderJson.TransferMode === 'standard' || comparisonLogs.BeforeOrderJson.TransferMode === '扣积分'">扣除积分</a-tag>
                  <a-tag color="purple" v-else-if="comparisonLogs.BeforeOrderJson.TransferMode === 'serviceCharge' || comparisonLogs.BeforeOrderJson.TransferMode === '扣服务费'">扣除服务费</a-tag>
                  <a-tag color="purple" v-else-if="comparisonLogs.BeforeOrderJson.TransferMode === '组合'">组合</a-tag>
                  <a-tag color="red" v-else>异常</a-tag>
                </a-descriptions-item>
              </div>
            </a-descriptions>

          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card style="width: 100%" title="之后数据" size="small">
            <a-descriptions
              size="small"
              :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
              title="定损信息"
            >
              <a-descriptions-item label="车牌号">
                {{ comparisonLogs.NowOrderJson.PlateNo }}
              </a-descriptions-item>
              <a-descriptions-item label="保险公司分类">
                {{ comparisonLogs.NowOrderJson.BaseInsurance }}
              </a-descriptions-item>
              <a-descriptions-item label="报案号/案件号">
                {{ comparisonLogs.NowOrderJson.ReportNo }}
              </a-descriptions-item>
              <a-descriptions-item label="定损员">
                {{ comparisonLogs.NowOrderJson.Assessor }}
              </a-descriptions-item>
              <a-descriptions-item label="定损金额">
                {{ comparisonLogs.NowOrderJson.DamageAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="修理厂">
                {{ comparisonLogs.NowOrderJson.RepairShop }}
              </a-descriptions-item>
              <a-descriptions-item label="修理厂联系电话">
                {{ comparisonLogs.NowOrderJson.RepairShopPhone }}
              </a-descriptions-item>
              <a-descriptions-item label="业务员姓名">
                {{ comparisonLogs.NowOrderJson.StaffName }}
              </a-descriptions-item>
              <a-descriptions-item label="业务员联系电话">
                {{ comparisonLogs.NowOrderJson.StaffPhone }}
              </a-descriptions-item>
              <a-descriptions-item label="是否开具发票">
                {{ comparisonLogs.NowOrderJson.NeedInvoice ? "是" : "否" }}
              </a-descriptions-item>
              <a-descriptions-item label="预估金额">
                {{ comparisonLogs.NowOrderJson.EstimatedAmount }}
              </a-descriptions-item>
              <a-descriptions-item label="订单备注">
                {{ comparisonLogs.NowOrderJson.CreatedRemarks }}
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions
              size="small"
              :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
              :title="`收款账户信息——${addForm.NeedInvoice ? '公账' : '私账'}`"
            >
              <a-descriptions-item label="开户行">
                {{ comparisonLogs.NowOrderJson.BankAccount }}
              </a-descriptions-item>
              <a-descriptions-item label="银行账户">
                {{ comparisonLogs.NowOrderJson.BankName }}
              </a-descriptions-item>
              <a-descriptions-item label="银行卡号">
                {{ comparisonLogs.NowOrderJson.BankCard }}
              </a-descriptions-item>
            </a-descriptions>
            <a-divider>银行回执单关联</a-divider>
            <el-table
              stripe
              :data="bankFlowTable"
              style="width: 100%">
              <el-table-column
                prop="flowNo"
                label="流水号">
              </el-table-column>
              <el-table-column
                prop="accountName"
                label="账号名称">
              </el-table-column>
              <el-table-column
                prop="account"
                label="账号">
              </el-table-column>
              <el-table-column
                prop="transactionTime"
                label="到账时间">
              </el-table-column>
              <el-table-column
                prop="credits"
                label="到账金额">
              </el-table-column>
              <el-table-column
                prop="claimIndemnity"
                label="订单占用金额">
              </el-table-column>
              <el-table-column
                prop="remarks"
                label="备注">
              </el-table-column>
            </el-table>
            <a-divider>费用信息</a-divider>
            <a-table :pagination="false" :rowKey="(record) => record.name" :columns="[{title: '费用名称', dataIndex: 'name'}, {title: '费用金额', dataIndex: 'amount'}]" :data-source="comparisonLogs.NowOrderJson.CostInfos">
            </a-table>
            <a-descriptions
              size="small"
              :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
              title="财务信息"
            >
              <div v-if="comparisonLogs.NowOrderJson.IsToAccessoriesOrder">
                转换为配件额度
              </div>
              <div v-else>
                <a-descriptions-item label="赔款到账日期">
                  {{
                    comparisonLogs.NowOrderJson.IndemnityDateTime
                      ? moment(comparisonLogs.NowOrderJson.IndemnityDateTime).format("YYYY-MM-DD")
                      : ""
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="转款日期">
                  {{
                    comparisonLogs.NowOrderJson.TransferDateTime
                      ? moment(comparisonLogs.NowOrderJson.TransferDateTime).format("YYYY-MM-DD")
                      : ""
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="修理厂转款金额">
                  {{ comparisonLogs.NowOrderJson.TransferAmount }}
                </a-descriptions-item>
                <a-descriptions-item label="合计应付">
                  {{ comparisonLogs.NowOrderJson.PayableAmount }}
                </a-descriptions-item>
                <a-descriptions-item label="服务费提点比例">
                  {{ comparisonLogs.NowOrderJson.CutPercent }}%
                </a-descriptions-item>
                <a-descriptions-item label="积分提点比例">
                  {{ comparisonLogs.NowOrderJson.CutPercentByScore }}%
                </a-descriptions-item>
                <a-descriptions-item label="贴点金额">
                  {{ comparisonLogs.NowOrderJson.SubsidyAmount }}
                </a-descriptions-item>
                <a-descriptions-item label="税费">
                  {{ comparisonLogs.NowOrderJson.Taxation }}
                </a-descriptions-item>
                <a-descriptions-item label="财务备注">
                  {{ comparisonLogs.NowOrderJson.FinancialRemarks }}
                </a-descriptions-item>
                <a-descriptions-item label="转款模式" v-if="comparisonLogs.NowOrderJson.TransferMode">
                  <a-tag color="green" v-if="comparisonLogs.BeforeOrderJson.transferMode === 'standard' || comparisonLogs.BeforeOrderJson.transferMode === '扣积分'">扣除积分</a-tag>
                  <a-tag color="purple" v-else-if="comparisonLogs.BeforeOrderJson.transferMode === 'serviceCharge' || comparisonLogs.BeforeOrderJson.transferMode === '扣服务费'">扣除服务费</a-tag>
                  <a-tag color="purple" v-else-if="comparisonLogs.BeforeOrderJson.transferMode === '组合'">组合</a-tag>
                  <a-tag color="red" v-else>异常</a-tag>
                </a-descriptions-item>
              </div>
            </a-descriptions>
          </a-card>
        </a-col>
      </a-row>
    </a-drawer>
  </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import licia from "licia";
import moment from "moment";
import DownloadJS from "downloadjs";
import screenfull from "screenfull";
import { AssessDamageOrder, Customer, Utils, BankFlow, LossRate, AppPaymentAccount } from "@/api";
import { aliveVM, mixinTableList } from "@/utils/mixin";

const INIT_SEARCH_FORM =
  '{"baseInsurance":"","repairShop":"","customer":"","transferDateTime":[],"createdAt":[],"orderUuids":[],"carOwner":"","plateNo":"","staffName":"","status":"","createUserName":"","belongOrg":"","lossShopName":"","isReject":null,"uuid":"","reportNo":"","matchBankFlow":"","isDeductionCharge":null}';
const columns = [
  {
    title: "订单时间",
    width: 150,
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 150,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "提交网点",
    width: 150,
    dataIndex: "createUserName",
  },
  {
    title: "车牌号",
    width: 120,
    dataIndex: "plateNo",
  },
  {
    title: "保险公司",
    width: 120,
    dataIndex: "baseInsurance",
  },
  {
    title: "报案号/案件号",
    width: 120,
    dataIndex: "reportNo",
  },
  {
    title: "定损员",
    width: 120,
    dataIndex: "assessor",
  },
  {
    title: "预估金额",
    width: 120,
    dataIndex: "estimatedAmount",
  },
  {
    title: "定损金额",
    width: 120,
    dataIndex: "damageAmount",
  },
  {
    title: "修理厂名称",
    width: 120,
    dataIndex: "repairShop",
  },
  {
    title: "修理厂联系电话",
    width: 120,
    dataIndex: "repairShopPhone",
  },
  {
    title: "业务员",
    width: 120,
    dataIndex: "staffName",
  },
  {
    title: "业务员手机号",
    width: 120,
    dataIndex: "staffPhone",
  },
  {
    title: "是否开具发票",
    width: 120,
    dataIndex: "needInvoice",
    scopedSlots: { customRender: "needInvoice" },
  },
  {
    title: "赔款到账日期",
    width: 120,
    dataIndex: "indemnityDateTime",
    scopedSlots: { customRender: "indemnityDateTime" },
  },
  {
    title: "转款日期",
    width: 120,
    dataIndex: "transferDateTime",
    scopedSlots: { customRender: "transferDateTime" },
  },
  {
    title: "修理厂转款金额",
    width: 120,
    dataIndex: "transferAmount",
  },
  {
    title: "合计应付",
    width: 120,
    dataIndex: "payableAmount",
  },
  {
    title: "服务费提点比例",
    width: 120,
    dataIndex: "cutPercent",
    scopedSlots: { customRender: "cutPercent" },
  },
  {
    title: "积分提点比例",
    width: 120,
    dataIndex: "cutPercentByScore",
    scopedSlots: { customRender: "cutPercentByScore" },
  },
  {
    title: "贴点金额",
    width: 120,
    dataIndex: "subsidyAmount",
  },
  {
    title: "税费",
    width: 120,
    dataIndex: "taxation",
  },
  {
    title: "银行回执单号",
    width: 120,
    dataIndex: "bankFlow",
  },
  {
    title: "收款开户行",
    width: 120,
    dataIndex: "bankName",
  },
  {
    title: "收款卡号",
    width: 120,
    dataIndex: "bankCard",
  },
  {
    title: "操作",
    key: "operation",
    width: 200,
    fixed: 'right',
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "AssessDamageOrder",
  mixins: [aliveVM, mixinTableList],
  components: {
    vueEasyPrint,
  },
  beforeRouteEnter (to, from, next) {
    if (to.params) {
      next(vm => {
        vm.isRouterSearch = true
        vm.formSearch(to.params.key,to.params.type)
      })
    } else {
      next()
    }
    
  },
  async created() {
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    const baseColumns = [];
    for (const it of columns) {
      baseColumns.push(it.title);
    }
    this.uploadSettlementUrl = `${this.$appBaseUrl}${AssessDamageOrder}/PostBackSettlementExcel`
    this.uploadSettlementHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    this.uploadInsDiscountAmountUrl = `${this.$appBaseUrl}${AssessDamageOrder}/PostBackInsDiscountAmountExcel`
    this.uploadInsDiscountAmountHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    var statusArray = []
    for (const key of Object.keys(this.statusDic)) {
      if (key === '400') continue
      statusArray.push({
        value: key,
        title: this.statusDic[key].text
      })
    }
    this.$set(this, 'statusArray', statusArray)
    this.$set(this, "baseColumns", baseColumns);
  },
  updated() {
    this.tableHeight =
      document.body.offsetHeight - this.$refs.searchForm.$el.offsetHeight - 331;
  },
  async mounted() {
    await this.getRepairShopList();
    await this.getBaseInsuranceNameList();
    this.fetch();
  },
  computed: {
    showColumns() {
      let c = [];
      for (const it of this.columns) {
        c.push(it.title);
      }
      return c;
    },
  },
  data() {
    return {
      isRouterSearch: false,
      // table使用
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${AssessDamageOrder}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      rowKey: "uuid",
      // 其他
      bankFlowTableVisible: false,
      bankFlowInfo: null,
      bankFlowTableData: [],
      bankFlowColumns: [
        {
          title: '操作',
          key: 'operation',
          width: 200,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: '到账时间',
          width: 150,
          dataIndex: 'transactionTime',
          scopedSlots: { customRender: 'transactionTime' },
        },
        {
          title: '备注',
          width: 150,
          dataIndex: 'remarks',
        },
        {
          title: '对方户名',
          width: 150,
          dataIndex: 'oppositeName',
        },
        {
          title: '到账金额',
          width: 150,
          dataIndex: 'credits',
        },
        {
          title: '流水号',
          width: 150,
          dataIndex: 'flowNo',
        },
        {
          title: '状态',
          width: 150,
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '导入批次号',
          width: 150,
          dataIndex: 'batchNo',
        },
        {
          title: '账号',
          width: 150,
          dataIndex: 'account',
        },
        {
          title: '账号名称',
          width: 150,
          dataIndex: 'accountName',
        },
        {
          title: '对方账号',
          width: 150,
          dataIndex: 'oppositeAccount',
        },
        {
          title: '对方开户行',
          width: 150,
          dataIndex: 'oppositeBankName',
        },
      ],
      bankFlowStatus: {
        '-2': {
          color: 'red',
          text: '删除'
        },
        '-1': {
          color: 'green',
          text: '未使用'
        },
        '1': {
          color: 'purple',
          text: '部分金额使用'
        },
        '999': {
          color: 'orange',
          text: '已使用'
        },
      },
      statusArray: [],
      statusDic: {
        100: {
          color: "cyan",
          text: "订单建立",
        },
        200: {
          color: "red",
          text: "待网点修改",
        },
        300: {
          color: "purple",
          text: "已到账",
        },
        400: {
          color: "orange",
          text: "待上传发票(已弃用流程节点)",
        },
        500: {
          color: "orange",
          text: "待客户申请转款",
        },
        510: {
          color: "orange",
          text: "待上传发票",
        },
        600: {
          color: "#f50",
          text: "已申请转款",
        },
        700: {
          color: "pink",
          text: "待转款",
        },
        999: {
          color: "green",
          text: "已完成",
        },
      },
      showOrderDetail: false,
      orderDetail: {},
      removeBeforeImage: {},
      lossShopNameBySearch: [],
      moment,
      tableHeight: 500,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      baseInsuranceNameList: [],
      lossRateList: [],
      sending: false,
      sendingHint: "",
      nowEditData: {},
      viewMode: 'View',
      // 列表类型
      listMode: "all",
      nowEditUuid: '',
      // 添加
      addFormModalShow: false,
      addFormModalMode: "create",
      quotaModeFormula: "",
      repairShopList: [],
      paymentBankList: [],
      customerOfflineScore: null,
      addForm: {
        plateNo: "",
        baseInsurance: "",
        lossRateUuid: "",
        reportNo: "",
        assessor: "",
        damageAmount: 0,
        repairShopId: "",
        repairShopPhone: "",
        staffName: "",
        staffPhone: "",
        needInvoice: "yes",
        estimatedAmount: 0,
        indemnityDateTime: "",
        transferDateTime: "",
        transferAmount: 0,
        payableAmount: 0,
        cutPercent: 0,
        cutPercentByScore: null,
        subsidyAmount: 0,
        taxation: 0,
        bankFlow: "",
        bankAccount: "",
        bankName: "",
        bankCard: "",
        paymentBankCard: '',
        paymentBankCardByPrivate: '',
        createdRemarks: "",
        financialRemarks: "",
        status: null,
        costInfos: [
          {
            name: "",
            amount: 0,
          },
          {
            name: "",
            amount: 0,
          },
          {
            name: "",
            amount: 0,
          },
        ],
        rescueFee: 0,
        rescueFeeBelong: '',
        bankAccountByCost: '',
        bankNameByCost: '',
        bankCardByCost: '',
        bankAccountByRescueFee: '',
        bankNameByRescueFee: '',
        bankCardByRescueFee: ''
      },
      addFormRules: {
        plateNo: [{ required: true, message: "请输入", trigger: "blur" }],
        baseInsurance: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        reportNo: [{ required: true, message: "请输入", trigger: "blur" }],
        assessor: [{ required: true, message: "请输入", trigger: "blur" }],
        damageAmount: [{ required: true, message: "请输入", trigger: "blur" }],
        repairShopId: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        estimatedAmount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        needInvoice: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        bankAccount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        bankCard: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        indemnityDateTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        transferDateTime: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        transferAmount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        cutPercent: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        subsidyAmount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        taxation: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
      // 发票上传
      uploadImageUuid: '',
      fileUploadHeaders: {},
      showUploadImageModal: false,
      imageFilesOrderInfoString: '',
      imageFileUrl: '',
      imageFiles: [],
      beforeImageFiles: [],
      removeImageFiles: [],
      // 暂存
      storageSendUuid: "",
      storageSendShow: false,
      storageSendRemarks: "",
      // 日志
      showLogsDrawer: false,
      showComparisonDrawer: false,
      comparisonLogs: null,
      // 退回
      isBackAccessoriesOrder: false,
      backModalShow: false,
      backMessage: '',
      // 银行回执数据
      bankFlowSearchFlags: [
        { value: 'plateNo', label: '匹配车牌' },
        { value: 'baseInsurance', label: '匹配保险公司' },
        { value: 'damageAmount', label: '匹配定损金额' },
      ],
      appointBankFlow: '',
      bankFlowSearchFlagsSelected: ['plateNo'],
      bankFlowCheckAmountShow: false,
      bankFlowMaxAmount: 0.01,
      bankFlowCheckAmount: 0,
      searchBankFlow: '',
      awaitPushBankFlowData: null,
      bankFlowTable: [],
      // 结算表
      exportTypeSelectShow: false,
      exportType: '',
      uploadSettlementExcel: [],
      showExportSettlementExcelDateSelect: false,
      exportSettlementExcelDate: [],
      showExportStatisticalDateSelect: false,
      exportStatisticalExcelTransferDateTime: [],
      exportStatisticalExcelInsOrderTime: [],
      // 保险贴费
      uploadInsDiscountAmountExcel: [],
      // transferModeHint
      transferModeHint: ''
    };
  },
  methods: {
    formSearch(key, type) {
      if (type == 'uuid') {
        this.$set(this, "searchForm", {
          uuid: key
        });
        this.expand = true
        this.searchClick();
      } else if (type == 'plateNo') {
        this.$set(this, "searchForm", {
          plateNo: key
        });
        this.searchClick();
      } else if (type == 'uuids') {
        this.$set(this, "searchForm", {
          orderUuids: key
        });
        this.searchClick();
      } else {
        this.$set(this, "searchForm", JSON.parse(INIT_SEARCH_FORM));
        this.isRouterSearch = false
      }
    },
    changeColumns(v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it);
        }
      }
      this.$set(this, "columns", c);
    },
    async reload() {
      this.$set(this, "searchParams", null);
      this.searchForm.resetFields();
      await this.fetch();
    },
    async cleanSearchClick() {
      this.isRouterSearch = false
      this.$set(this, "searchParams", null);
      this.$set(this, "searchForm", JSON.parse(INIT_SEARCH_FORM));
      this.$set(this, "lossShopNameBySearch", []);
      await this.fetch();
    },
    async searchClick() {
      if (this.loading) return
      this.pagination.current = 1;
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));

      const searchParams = {};
      for (const k of Object.keys(searchForm)) {
        let it = searchForm[k];
        if (k === "createdAt" || k === 'transferDateTime') {
          if (!it || it.length < 1) continue;
          it[0] = moment(it[0]).format("YYYY-MM-DD") + " 00:00:00";
          it[1] = moment(it[1]).format("YYYY-MM-DD") + " 23:59:59";
          searchParams[k] = it;
        }
        if (k === 'isToAccessoriesOrder' || k === 'isDeductionCharge') {
          if (it === 'yes') {
            searchParams[k] = true;
          } else if (it === 'no') {
            searchParams[k] = false;
          }
          continue;
        }
        if (k === "status") {
          it = parseInt(it);
        }
        if (licia.isNum(it)) {
          searchParams[k] = it;
          continue;
        }
        if (licia.isBool(it)) {
          searchParams[k] = it;
          continue;
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it;
        }
      }
      this.$set(this, "searchParams", searchParams);
      this.fetch();
    },
    // 其他
    async getPaymentBankList() {
      const apiRes = await this.$axios
        .get(`${AppPaymentAccount}/SelectList`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return null;
      }
      this.paymentBankList = apiRes.data
    },
    async baseInsuranceChange(value) {
      this.$set(this.addForm, 'lossRateUuid', '')
      await this.getLossRateList(value)
    },
    async addFormShowHandle() {
      await this.getPaymentBankList();
      await this.getRepairShopList();
      await this.getBaseInsuranceNameList();
      this.$set(this, 'bankFlowTable', [])
      this.$set(this, 'addForm', {
        plateNo: "",
        baseInsurance: "",
        reportNo: "",
        assessor: "",
        damageAmount: 0,
        repairShopId: "",
        repairShopPhone: "",
        staffName: "",
        staffPhone: "",
        needInvoice: "yes",
        estimatedAmount: 0,
        indemnityDateTime: "",
        transferDateTime: "",
        transferAmount: 0,
        payableAmount: 0,
        cutPercent: 0,
        cutPercentByScore: null,
        subsidyAmount: 0,
        taxation: 0,
        bankFlow: "",
        bankAccount: "",
        bankName: "",
        bankCard: "",
        paymentBankCard: '',
        paymentBankCardByPrivate: '',
        createdRemarks: "",
        financialRemarks: "",
        status: null,
        costInfos: [
          {
            name: "",
            amount: 0,
          },
          {
            name: "",
            amount: 0,
          },
          {
            name: "",
            amount: 0,
          },
        ],
        rescueFee: 0,
        rescueFeeBelong: '',
        bankAccountByCost: '',
        bankNameByCost: '',
        bankCardByCost: '',
        bankAccountByRescueFee: '',
        bankNameByRescueFee: '',
        bankCardByRescueFee: ''
      })
      this.addFormModalMode = 'Create'
      this.addFormModalShow = true;
    },
    onChangeSelectOrg(_, selectedOptions) {
      if (selectedOptions && selectedOptions.length > 0) {
        let lossShopName = "";
        let lossShopUuid = "";
        const lastData = selectedOptions[selectedOptions.length - 1];
        lossShopName = lastData.title;
        lossShopUuid = lastData.key;
        this.addForm.lossShopName = lossShopName;
        this.addForm.lossShopUuid = lossShopUuid;
        if (lastData.data && lastData.data.BaseInfo) {
          this.addForm.staffName = lastData.data.BaseInfo.Staff;
        }
      } else {
        this.addForm.lossShopName = "";
      }
    },
    onChangeSelectOrgBySearch(_, selectedOptions) {
      if (selectedOptions && selectedOptions.length > 0) {
        let lossShopName = "";
        lossShopName = selectedOptions[selectedOptions.length - 1].title;
        this.searchForm.lossShopName = lossShopName;
      } else {
        this.searchForm.lossShopName = "";
      }
    },
    async getDetail(uuid) {
      const apiRes = await this.$axios
        .get(`${AssessDamageOrder}/Detail?uuid=${uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return null;
      }
      this.transferModeHint = ""
      this.transferModeHint = this.getTransferModeHint(apiRes.data)
      return apiRes.data;
    },
    getTransferModeHint(data) {
      const serviceCharge = data.actualTransferServiceCharge
      const serviceChargeByScore = data.actualTransferScore
      let rescueFee = data.rescueFee ? data.rescueFee : 0
      let rescueFeeReduce = rescueFee
      if (data.rescueFeeBelong === 'lossShop') {
        rescueFeeReduce = 0
      }
      let bindOrderCostInfosAmount = 0
      if (data.costInfos) {
        for (const it of data.costInfos) {
          if (!it.amount) continue;
          bindOrderCostInfosAmount += it.amount
        }
      }
      let invoiceAmount = 0;
      
      if (data.needInvoice) {
        invoiceAmount = data.actualTransferAmount - bindOrderCostInfosAmount
      }

      return `可扣除服务费${serviceCharge}${serviceChargeByScore ? `、积分手续费${serviceChargeByScore}` : ''}后转款${data.actualTransferAmount}${rescueFeeReduce ? '' : `(含${rescueFee}施救费)`}元${invoiceAmount ? `，应开票${invoiceAmount}元` : ''}`
    },
    async handleView(data, viewMode = 'View') {
      const detail = await this.getDetail(data.uuid);
      if (!detail) return;
      this.$set(this, "orderDetail", detail);
      if (detail.bankFlowInfos && detail.bankFlowInfos.length > 0) {
        this.$set(this, "bankFlowTable", detail.bankFlowInfos)
      } else {
        this.$set(this, "bankFlowTable", [])
      }
      this.viewMode = viewMode
      this.showOrderDetail = true;
    },
    async handleBack(data) {
      this.isBackAccessoriesOrder = false;
      this.nowEditUuid = data.uuid;
      this.handleBackShow();
    },
    async handleBackAccessories(data) {
      this.isBackAccessoriesOrder = true;
      this.nowEditUuid = data.uuid;
      this.handleBackShow();
    },
    async handleEdit(data, addFormModalMode = "Edit") {
      const lock = await this.handleLockOrder(data)
      if (!lock) {
        this.$message.error("进入订单编辑锁定失败，可能他人正在操作该订单")
        return
      }
      await this.getPaymentBankList();
      const detail = await this.getDetail(data.uuid);
      if (!detail) return;
      this.$set(this, 'orderDetail', detail)
      if (detail.bankFlowInfos && detail.bankFlowInfos.length > 0) {
        this.$set(this, "bankFlowTable", detail.bankFlowInfos)
      } else {
        this.$set(this, "bankFlowTable", [])
      }
      await this.getLossRateList(detail.baseInsurance);
      this.bankFlowInfo = detail.bankFlowInfo || null
      this.nowEditUuid = detail.uuid;
      this.nowEditData = detail;
      this.addFormModalMode = addFormModalMode;
      const addForm = {};
      addForm.plateNo = detail.plateNo
      addForm.baseInsurance = detail.baseInsurance
      addForm.reportNo = detail.reportNo
      addForm.assessor = detail.assessor
      addForm.damageAmount = detail.damageAmount
      addForm.repairShopId = detail.repairShopId
      addForm.repairShopPhone = detail.repairShopPhone
      addForm.staffName = detail.staffName
      addForm.staffPhone = detail.staffPhone
      addForm.needInvoice = detail.needInvoice ? 'yes' : 'no'
      addForm.estimatedAmount = detail.estimatedAmount
      addForm.indemnityDateTime = detail.indemnityDateTime ? moment(detail.indemnityDateTime).format("YYYY-MM-DD") : ''
      addForm.transferDateTime = detail.transferDateTime ? moment(detail.transferDateTime).format("YYYY-MM-DD") : ''
      addForm.transferAmount = detail.transferAmount
      addForm.payableAmount = detail.payableAmount ? detail.payableAmount : 0
      addForm.cutPercent = detail.cutPercent
      addForm.cutPercentByScore = detail.cutPercentByScore
      addForm.subsidyAmount = detail.subsidyAmount
      let taxation = 0;
      let claimIndemnity = 0;
      for (const b of detail.bankFlowInfos) {
        claimIndemnity += b.claimIndemnity
      }
      if (detail.paymentBankCardType === 'company' || detail.paymentBankCardTypeByPrivate === 'company') {
        taxation = Math.round(claimIndemnity * 0.0112 * 100) / 100
      } else {
        taxation = Math.round(claimIndemnity * 0.0612 * 100) / 100
      }
      addForm.taxation = detail.taxation ? detail.taxation : taxation
      addForm.bankFlow = detail.bankFlow
      addForm.bankAccount = detail.bankAccount ? detail.bankAccount : ''
      addForm.bankName = detail.bankName
      addForm.bankCard = detail.bankCard
      addForm.paymentBankCard = detail.paymentBankCard ? detail.paymentBankCard : ''
      addForm.paymentBankCardByPrivate = detail.paymentBankCardByPrivate ? detail.paymentBankCardByPrivate : ''
      addForm.createdRemarks = detail.createdRemarks
      addForm.financialRemarks = detail.financialRemarks
      addForm.status = detail.status
      addForm.lossRateUuid = detail.lossRateUuid ? detail.lossRateUuid : ''
      addForm.rescueFee = detail.rescueFee ? detail.rescueFee : 0
      addForm.rescueFeeBelong = detail.rescueFeeBelong ? detail.rescueFeeBelong : ''
      addForm.bankAccountByCost = detail.bankAccountByCost ? detail.bankAccountByCost : ''
      addForm.bankNameByCost = detail.bankNameByCost ? detail.bankNameByCost : ''
      addForm.bankCardByCost = detail.bankCardByCost ? detail.bankCardByCost : ''
      addForm.bankAccountByRescueFee = detail.bankAccountByRescueFee ? detail.bankAccountByRescueFee : ''
      addForm.bankNameByRescueFee = detail.bankNameByRescueFee ? detail.bankNameByRescueFee : ''
      addForm.bankCardByRescueFee = detail.bankCardByRescueFee ? detail.bankCardByRescueFee : ''

      if (!addForm.indemnityDateTime && this.bankFlowInfo && this.bankFlowInfo.transactionTime) {
        addForm.indemnityDateTime = moment(this.bankFlowInfo.transactionTime).format("YYYY-MM-DD")
      }

      if (detail.costInfos) {
        if (detail.costInfos.length < 3) {
          for (let index = detail.costInfos.length - 1; index < 3; index++) {
            detail.costInfos.push({
                name: "",
                amount: 0,
              })
          }
        }
        addForm.costInfos = detail.costInfos
      } else {
        addForm.costInfos = [
          {
            name: "",
            amount: 0,
          },
          {
            name: "",
            amount: 0,
          },
          {
            name: "",
            amount: 0,
          },
        ]
      }
      console.log(this.nowEditData.status, addForm.payableAmount)
      if ((this.nowEditData.status >= 600 && this.nowEditData.status < 999) && !addForm.payableAmount) {
        let costAmount = 0;

        if (addForm.costInfos) {
          for (const it of addForm.costInfos) {
            costAmount += it.amount
          }
        }
        console.log("0000", addForm.rescueFeeBelong)
        if (addForm.rescueFeeBelong == "lossShop") {
          addForm.payableAmount = Math.round((addForm.transferAmount + (costAmount * 0.95)) * 100) / 100
        } else {
          addForm.payableAmount = Math.round((addForm.transferAmount + (addForm.rescueFee * 0.95) + (costAmount * 0.95)) * 100) / 100
        }
      }

      this.$set(this, "addForm", addForm);
      await this.getRepairShopList();
      await this.getBaseInsuranceNameList();
      this.addFormModalShow = true;
    },
    async storageSendOk() {
      const res = await this.$axios
        .put(
          `${AssessDamageOrder}/StorageOrderSend?uuid=${this.storageSendUuid}`,
          {
            storageSendRemarks: this.storageSendRemarks,
          }
        )
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      this.storageSendShow = false;
      if (!res.status) {
        this.$message.error(res.message);
        return false;
      }
      this.$message.success("已暂存");
      this.fetch();
    },
    async handleUnstorageOrder(data) {
      const res = await this.$axios
        .put(`${AssessDamageOrder}/UnstorageOrderSend?uuid=${data.uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return false;
      }
      this.$message.success("已撤销暂存");
      this.fetch();
    },
    handleAddOk() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        const postData = JSON.parse(JSON.stringify(this.addForm));
        postData.bankFlowInfos = JSON.parse(JSON.stringify(this.bankFlowTable));
        if (!postData.estimatedAmount) {
          this.$message.error("请填写预估积分");
          return;
        }
        if (postData.needInvoice === "yes") {
          postData.needInvoice = true;
        } else {
          postData.needInvoice = false;
        }
        let url = '';
        if (this.addFormModalMode === 'Create') {
          if (postData.rescueFee && !postData.rescueFeeBelong) {
            this.$message.error("填写了施救费必须选择施救费归属");
            return;
          }
          url = `${AssessDamageOrder}/Create`
        } else if (this.addFormModalMode === 'FinancialConfirm') {
          url = `${AssessDamageOrder}/FinancialConfirm?uuid=${this.nowEditUuid}`
        } else if (this.addFormModalMode === 'EditByCreatedUser') {
          url = `${AssessDamageOrder}/EditOrderByCreatedUser?uuid=${this.nowEditUuid}`
        } else if (this.addFormModalMode === 'EditAll') {
          url = `${AssessDamageOrder}/EditAll?uuid=${this.nowEditUuid}`
        }
        const res = await this.$axios
          .post(url, postData)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });

        if (!res.status) {
          this.$message.error(res.message);
          return;
        }

        this.addFormModalShow = false;
        this.$message.success("操作成功");
        this.fetch();
      });
    },
    async exportExcel() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      const searchParams = {};
      for (const k of Object.keys(searchForm)) {
        let it = searchForm[k];
        if (k === "createdAt" || k === 'transferDateTime') {
          if (!it || it.length < 1) continue;
          it[0] = moment(it[0]).format("YYYY-MM-DD") + " 00:00:00";
          it[1] = moment(it[1]).format("YYYY-MM-DD") + " 23:59:59";
          searchParams[k] = it;
        }
        if (k === 'isToAccessoriesOrder') {
          if (it === 'yes') {
            searchParams[k] = true;
          } else if (it === 'no') {
            searchParams[k] = false;
          }
          continue;
        }
        if (k === "status") {
          it = parseInt(it);
        }
        if (licia.isNum(it)) {
          searchParams[k] = it;
          continue;
        }
        if (licia.isBool(it)) {
          searchParams[k] = it;
          continue;
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it;
        }
      }
      this.sending = true;
      this.sendingHint = "正在获取数据";
      const apiRes = await this.$axios
        .post(`${AssessDamageOrder}/ExportExcel`, {
          results: 20000,
          page: 1,
          searchParams,
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      this.sending = false;
      if (!apiRes.status) {
        this.sending = false;
        this.$message.error(apiRes.message);
        return;
      }
      DownloadJS(window.atob(apiRes.data.data), "定损单列表数据.xlsx");
      if (apiRes.data.errorList && apiRes.data.errorList.length > 0) {
        this.$notification.error({ message: '存在错误', description: apiRes.data.errorList })
      }
    },
    async deleteBySelf(data) {
      const res = await this.$axios
        .delete(`${AssessDamageOrder}/DeleteByCreatedUser?uuid=${data.uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.addFormModalShow = false;
      this.$message.success("操作成功");
      this.fetch();
    },
    async deleteByRole(data) {
      const res = await this.$axios
        .delete(`${AssessDamageOrder}/Delete?uuid=${data.uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.addFormModalShow = false;
      this.$message.success("操作成功");
      this.fetch();
    },
    handleToUpload() {
      this.$router.push({ name: 'InvoiceImageTableList' })
    },
    async handleUploadInvoice(data) {
      const beforeImageFiles = []
      const detail = await this.getDetail(data.uuid);
      if (detail && detail.invoiceImages && detail.invoiceImages.length > 0) {
        let i = 0;
        for(const it of detail.invoiceImages) {
          beforeImageFiles.push({
             uid: `${i}`,
             name: `${i}.png`,
             path: it,
             status: 'done',// custom error message to show
             url: `${this.$appBaseUrl}${it}`,
          })
          i++;
        }
      }
      this.$set(this, 'beforeImageFiles', beforeImageFiles)
      console.log(beforeImageFiles)
      this.imageFiles = []
      this.removeImageFiles = []
      this.uploadImageUuid = data.uuid;
      this.imageFileUrl = `${this.$appBaseUrl}${AssessDamageOrder}/UploadInvoice?uuid=${data.uuid}`
      this.imageFilesOrderInfoString = `${data.plateNo}(${data.uuid})`
      this.showUploadImageModal = true
    },
    async handleLockOrder(data) {
      const res = await this.$axios
        .put(`${AssessDamageOrder}/LockOrder`, {
          uuid: data.uuid,
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return false;
      }
      data.locked = true;
      data.lockedByUser = this.$root.$data.privateState.userInfo.uuid;
      return true;
    },
    async handleUnlockOrder(data) {
      const res = await this.$axios
        .put(`${AssessDamageOrder}/UnlockOrder`, {
          uuid: data.uuid,
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.addFormModalShow = false;
      this.$message.success("操作成功");
      this.fetch();
    },
    printDetail() {
      // console.log(this.$refs.orderDetailRef)
      this.$refs.easyPrint.print();
    },
    async getBaseInsuranceNameList() {
      const baseNameListRes = await this.$axios
        .get(`${Utils}/InsuranceBaseList`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!baseNameListRes.status) {
        this.$message.error(baseNameListRes.message);
        return;
      }
      this.baseInsuranceNameList = baseNameListRes.data;
    },
    async getLossRateList(value) {
      const lossRateListRes = await this.$axios
        .get(`${LossRate}/SelectList?baseInsurance=${value}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!lossRateListRes.status) {
        this.$message.error(lossRateListRes.message);
        return;
      }
      this.lossRateList = lossRateListRes.data;
    },
    async getRepairShopList() {
      const res = await this.$axios
        .get(`${Customer}/SelectListByOrganization`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.$set(this, "repairShopList", res.data);
    },
    filterRepairShopOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    GetPayableAmount(orderDetail) {
      let costAmount = 0;

        if (orderDetail.costInfos) {
          for (const it of orderDetail.costInfos) {
            costAmount += it.amount
          }
        }
      if (orderDetail.transferAmount) {
        if (orderDetail.rescueFeeBelong == "lossShop") {
          return Math.round((orderDetail.transferAmount + (costAmount * 0.95)) * 100) / 100
        } else {
          return Math.round((orderDetail.transferAmount + (orderDetail.rescueFee * 0.95) + costAmount * 0.95) * 100) / 100
        }
      }
    },
    changeRescueFeeBelong(value) {
      let costAmount = 0;

        if (this.addForm.costInfos) {
          for (const it of this.addForm.costInfos) {
            costAmount += it.amount
          }
        }
      if (this.addFormModalMode === 'FinancialConfirm') {
        if (value == "lossShop") {
          this.addForm.payableAmount = Math.round((this.addForm.transferAmount + (costAmount * 0.95)) * 100) / 100
        } else if (value == "other") {
          this.addForm.payableAmount = Math.round((this.addForm.transferAmount + (this.addForm.rescueFee * 0.95) + (costAmount * 0.95)) * 100) / 100
        }
      }
    },
    handleChangeRepairShopSelect(value) {
      let data = null;
      for (const it of this.repairShopList) {
        if (it.uuid === value) {
          data = it;
          break;
        }
      }
      if (data) {
        if (data.cutPercent) {
          this.$set(this.addForm, "cutPercent", data.cutPercent);
        }
        if (data.cutPercentByScore) {
          this.$set(this.addForm, "cutPercentByScore", data.cutPercentByScore);
        }
        this.$set(this.addForm, "repairShopPhone", data.phone);
        this.$set(this.addForm, "staffName", data.staffName);
        this.$set(this.addForm, "staffPhone", data.staffPhone);
        this.matchBankInfo(data.bankInfo);
        this.getOfflineScore(data.uuid);
      }
    },
    handelChangeNeedInvoiceSelect() {
      if (!this.addForm.repairShopId) return;
      let data = null;
      for (const it of this.repairShopList) {
        if (it.uuid === this.addForm.repairShopId) {
          data = it;
          break;
        }
      }
      if (data) {
        this.matchBankInfo(data.bankInfo);
      }
    },
    matchBankInfo(bankInfo) {
      if (bankInfo) {
        if (this.addForm.needInvoice === "yes" && bankInfo.company) {
          this.$set(this.addForm, "bankAccount", bankInfo.company.bankAccount);
          this.$set(this.addForm, "bankName", bankInfo.company.bankName);
          this.$set(this.addForm, "bankCard", bankInfo.company.bankCard);
        } else if (this.addForm.needInvoice === "no" && bankInfo.self) {
          this.$set(this.addForm, "bankAccount", bankInfo.self.bankAccount);
          this.$set(this.addForm, "bankName", bankInfo.self.bankName);
          this.$set(this.addForm, "bankCard", bankInfo.self.bankCard);
        }
      }
    },
    async getOfflineScore(uuid) {
      const res = await this.$axios
        .get(`${Customer}/GetOfflineScore?uuid=${uuid}`)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.customerOfflineScore = null;
        return;
      }
      this.customerOfflineScore = res.data
    },
    async onSearchBankFlowShow() {
      this.appointBankFlow = ""
      this.bankFlowSearchFlagsSelected = ["plateNo"]
      await this.onSearchBankFlow()
      this.bankFlowTableVisible = true
    },
    async onSearchBankFlow() {
      this.bankFlowTableData = []
      const apiRes = await this.$axios.post(`${BankFlow}/GetSearchList`, {
        flags: this.bankFlowSearchFlagsSelected,
        'appointBankFlow': this.appointBankFlow,
        'damageAmount': this.addForm.damageAmount ? this.addForm.damageAmount : null,
        'plateNo': this.addForm.plateNo,
        'baseInsurance': this.addForm.baseInsurance
      })
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(`获取数据错误：${apiRes.message}`)
        return
      }
      if (licia.isArr(apiRes.data) && apiRes.data.length > 0) {
        this.bankFlowTableData = apiRes.data
        return
      }
      this.$message.error('没有查询到数据')
    },
    handleSelectBankFlowCancel() {
      this.bankFlowTableVisible = false
    },
    selectBankFlowHandle(data) {
      for (const it of this.bankFlowTable) {
        if (it.flowNo == data.flowNo) {
          this.$message.error(`${it.flowNo}已选择`)
          return
        }
      }
      this.addForm.indemnityDateTime = moment(data.transactionTime).format("YYYY-MM-DD")
      this.awaitPushBankFlowData = data
      this.bankFlowCheckAmount = 0
      this.bankFlowCheckAmountShow = true
    },
    beforeUploadImage(file) {
      this.imageFiles = [...this.imageFiles, file]
      return false;
    },
    async handleSetImageOk() {
      this.sending = true;
      this.sendingHint = "正在处理数据";
      if (this.removeImageFiles && this.removeImageFiles.length > 0) {
        await this.removeOrderImages()
      }
      if (this.imageFiles.length < 1) {
        this.sending = false;
        this.showUploadImageModal = false;
        return
      }
      const formData = new FormData();
      for (const it of this.imageFiles) {
        formData.append('imageFiles', it, it.name)
      }
      const res = await this.$axios
        .post(this.imageFileUrl, formData)
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      this.sending = false;
      this.showUploadImageModal = false;
      if (!res.status) {
        this.$message.error(res.message);
        return false;
      }
      const addImageKey = `${new Date().getTime()}`;
      const beforeImageFiles = [...this.beforeImageFiles]
      let i = 0;
      for (const it of res.data) {
        beforeImageFiles.push({
             uid: `${addImageKey}_${i}`,
             name: `${i}.png`,
             path: it,
             status: 'done',// custom error message to show
             url: `${this.$appBaseUrl}${it}`,
          })
        i++;
      }
      this.$set(this, 'beforeImageFiles', beforeImageFiles)
      this.$message.success("图片上传成功")
      this.fetch();
    },
    handleRemoveImage(file) {
      const index = this.beforeImageFiles.indexOf(file);
      if (index < 0) return
      const newFileList = this.beforeImageFiles.slice();
      this.removeImageFiles.push(file.path)
      newFileList.splice(index, 1);
      this.beforeImageFiles = newFileList;
    },
    async removeOrderImages() {
      const apiRes = await this.$axios.post(`${AssessDamageOrder}/RemoveOrderImages?uuid=${this.uploadImageUuid}`, {
        removeImages: this.removeImageFiles
      })
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
      if (!apiRes.status) {
        throw new Error(apiRes.message)
      }
    },
    // changeUploadImage(info) {
    //   this.imageFiles = info.fileList
    //   if (info.file.status === 'error') {
    //     this.$message.error('上传文件失败，请尝试重新登录');
    //     return
    //   } else if (info.file.status === 'done') {
    //     if (!info.file.response.status) {
    //       this.$message.error(info.file.response.message);
    //       info.file.status = 'error'
    //       info.imageFiles[info.fileList.length - 1].status = 'error'
    //       return;
    //     }
    //     this.showUploadImageModal = false
    //     this.fetch();
    //   }
    // },
    async handleConfirmTransfer() {
      const lock = await this.handleLockOrder(this.orderDetail)
      if (!lock) {
        this.$message.error("进入订单编辑锁定失败，可能他人正在操作该订单")
        return
      }
      this.sending = true;
      this.sendingHint = "正在处理数据";
      const apiRes = await this.$axios.put(`${AssessDamageOrder}/ConfirmTransfer?uuid=${this.orderDetail.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.sending = false;
      if (!apiRes.status) {
        this.$message.error(`获取数据错误：${apiRes.message}`)
        return
      }
      this.showOrderDetail = false
      this.fetch();
    },
    handleBackShow() {
      this.addFormModalShow = false
      this.backMessage = ""
      this.backModalShow = true
    },
    async handleBackOk() {
      if (!this.backMessage) {
        this.$message.error('请填写退回信息');
        return
      }
      if (!this.nowEditUuid) {
        this.$message.error('获取当前编辑订单Id错误');
        return
      }
      this.backModalShow = false
      let url = `${AssessDamageOrder}/Back?uuid=${this.nowEditUuid}`;
      if (this.isBackAccessoriesOrder) {
        url = `${AssessDamageOrder}/BackAccessories?uuid=${this.nowEditUuid}`;
      }
      const res = await this.$axios
        .put(url, {
          message: this.backMessage
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return false;
      }
      this.$message.success("已完成退回");
      this.fetch();
    },
    async handleTagShowLogs(data) {
      const detail = await this.getDetail(data.uuid);
      if (!detail) return;
      this.$set(this, "orderDetail", detail);
      this.showLogsDrawer = true
    },
    removeBankFlowTableData(index, rows) {
      rows.splice(index, 1)
    },
    handleCheckBankFlowAmount() {
      if (!this.bankFlowCheckAmount || this.bankFlowCheckAmount < 0.01) {
        this.$message.error("请输入订单占用金额")
        return
      }
      this.awaitPushBankFlowData.claimIndemnity = this.bankFlowCheckAmount
      this.bankFlowTable.push(JSON.parse(JSON.stringify(this.awaitPushBankFlowData)))
      let amount = 0
      for(const it of this.bankFlowTable) {
        amount += it.claimIndemnity
      }
      this.addForm.damageAmount = amount
      this.bankFlowTableVisible = false
      this.bankFlowCheckAmountShow = false
    },
    async exportSettlementExcelClick(type)
    {
      this.exportType = type
      if (type == 'statistics_ins' || type == 'statistics_settlement') {
        this.exportStatisticalExcelTransferDateTime = []
        this.exportStatisticalExcelInsOrderTime = []
        this.showExportStatisticalDateSelect = true
        return;
      }
      this.exportTypeSelectShow = false
      this.exportSettlementExcelDate = []
      this.showExportSettlementExcelDateSelect = true
    },
    async exportSettlementExcel() {
      if (!this.exportSettlementExcelDate || this.exportSettlementExcelDate.length < 2) {
        this.$message.error('请选择导出月份');
        return;
      }
      const searchParams = {
        transferDateTime: [this.exportSettlementExcelDate[0].format('YYYY-MM-DD') + ' 00:00:00', this.exportSettlementExcelDate[1].format('YYYY-MM-DD')  + ' 23:59:59']
      }
      
      this.sending = true
      this.sendingHint = '正在获取数据'
      if (this.exportType == 'statistics') {
        const apiRes = await this.$axios.post(`${AssessDamageOrder}/ExportStatistical`, {
          results: 20000,
          page: 1,
          searchParams,
        }).catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
        this.sending = false
        if (!apiRes.status) {
          this.sending = false
          this.$message.error(apiRes.message)
          return
        }
        this.showExportSettlementExcelDateSelect = false
        DownloadJS(window.atob(apiRes.data), '统计表数据.xlsx')
        return
      } else if (this.exportType == 'statistics_ins') {
        const apiRes = await this.$axios.post(`${AssessDamageOrder}/ExportStatisticalByIns`, {
          results: 20000,
          page: 1,
          searchParams,
        }).catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
        this.sending = false
        if (!apiRes.status) {
          this.sending = false
          this.$message.error(apiRes.message)
          return
        }
        this.showExportSettlementExcelDateSelect = false
        DownloadJS(window.atob(apiRes.data), '理赔业务保司分类表.xlsx')
        return
      } else if (this.exportType == 'statistics_settlement') {
        const apiRes = await this.$axios.post(`${AssessDamageOrder}/ExportStatisticalBySettlement`, {
          results: 20000,
          page: 1,
          searchParams,
        }).catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
        this.sending = false
        if (!apiRes.status) {
          this.sending = false
          this.$message.error(apiRes.message)
          return
        }
        this.showExportSettlementExcelDateSelect = false
        DownloadJS(window.atob(apiRes.data), '理赔业务核算表及分配表.xlsx')
        return
      }
      const apiRes = await this.$axios.post(`${AssessDamageOrder}/SettlementExcel?type=${this.exportType}`, {
        results: 20000,
        page: 1,
        searchParams,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.showExportSettlementExcelDateSelect = false
      DownloadJS(window.atob(apiRes.data), '结算表数据.xlsx')
    },
    async exportStatisticalExcel() {
      if (!this.exportStatisticalExcelTransferDateTime || this.exportStatisticalExcelTransferDateTime.length != 2) {
        this.$message.error('请选择转款时间范围');
        return;
      }
      if (!this.exportStatisticalExcelInsOrderTime || this.exportStatisticalExcelInsOrderTime.length != 2) {
        this.$message.error('请选择保费时间范围');
        return;
      }
      const transferDateTime = [this.exportStatisticalExcelTransferDateTime[0].format("YYYY-MM-DD") + " 00:00:00", this.exportStatisticalExcelTransferDateTime[1].format("YYYY-MM-DD") + " 23:59:59"]
      const insOrderTime = [this.exportStatisticalExcelInsOrderTime[0].format("YYYY-MM-DD") + " 00:00:00", this.exportStatisticalExcelInsOrderTime[1].format("YYYY-MM-DD") + " 23:59:59"]
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.post(`${AssessDamageOrder}/ExportStatisticalByType1?type=${this.exportType}`, {
        transferDateTime,
        insOrderTime
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.showExportSettlementExcelDateSelect = false
      DownloadJS(window.atob(apiRes.data), '结算表数据.xlsx')
    },
    handleChangeUploadSettlementExcel(info) {
      this.uploadSettlementExcel = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          return;
        }
        this.uploadSettlementExcel = []
        this.$message.success('回传结算表成功！')
        this.fetch();
      }
    },
    handleChangeUploadInsDiscountAmountExcel(info) {
      this.uploadInsDiscountAmountExcel = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          return;
        }
        this.uploadInsDiscountAmountExcel = []
        this.$message.success('回传保险贴费成功！')
        this.fetch();
      }
    },
    showComparisonClick(index) {
      this.comparisonLogs = this.orderDetail.logs[index]
      this.showComparisonDrawer = true
    },
    changePaymentBank() {
      let isCompany = false;
      let claimIndemnity = 0;
      for (const b of this.nowEditData.bankFlowInfos) {
        claimIndemnity += b.claimIndemnity
      }
      if (this.addForm.paymentBankCard) {
        for (const it of this.paymentBankList) {
          if (it.bankCard == this.addForm.paymentBankCard) {
            if (it.type == 'company') {
              isCompany = true;
              break
            }
          }
          if (it.bankCard == this.addForm.paymentBankCardByPrivate) {
            if (it.type == 'company') {
              isCompany = true;
              break
            }
          }
        }
      }
      let taxation = 0;
      if (isCompany) {
        taxation = Math.round(claimIndemnity * 0.0112 * 100) / 100
      } else {
        taxation = Math.round(claimIndemnity * 0.0612 * 100) / 100
      }
      this.addForm.taxation = taxation
    },
    changeLossRateUuid(value) {
      let item = null;
      for(const it of this.lossRateList) {
        if (it.uuid == value) {
          item = it;
          break;
        }
      }
      if (item && item.chargeRate) {
        this.$set(this.addForm, 'cutPercent', item.chargeRate);
      }
    },
    windowOpen(url) {
      window.open(url, '_blank');
    }
  },
};
</script>

<style scoped>
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>